// Settings
// ========

// Version
// -------
$su-version: 1.1;


// Default Settings
// ----------------
// PRIVATE: The basic settings
$susy-defaults: (
  columns: 4,
  gutters: .25,
);


// User Settings
// -------------
// - Define the $susy variable with a map of your own settings.
// - Set EITHER $column-width OR $container
// - Use $column-width for static layouts
$susy: () !default;


// Susy Defaults
// -------------
// PRIVATE: Add defaults to Susy
@mixin susy-defaults(
  $defaults
) {
  $susy-defaults: map-merge($susy-defaults, $defaults) !global;
}


// Susy Set
// --------
// Change one setting
// - $key   : setting name
// - $value : setting value
@mixin susy-set(
  $key-value...
) {
  $susy: _susy-deep-set($susy, $key-value...) !global;
}


// Susy Get
// --------
// Return one setting from a grid
// - $key     : <keyword>
// - $layout  : <settings>
@function susy-get(
  $key,
  $layout: map-merge($susy-defaults, $susy)
) {
  $layout: parse-grid($layout);
  $_options: $layout $susy $susy-defaults;
  $_break: false;
  $_return: null;

  @each $opt in $_options {
    @if type-of($opt) == map and not($_break) {
      $_keyset: _susy-deep-has-key($opt, $key...);
      @if $_keyset {
        $_return: _susy-deep-get($opt, $key...);
        $_break: true;
      }
    }
  }

  @return $_return;
}
