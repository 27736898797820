@import "shared";

// display:box; must be used for any of the other flexbox mixins to work properly
@mixin display-box {
  @include experimental-value(display, box,
    -moz, -webkit, not(-o), -ms, not(-khtml), official
  );
}

// Default box orientation, assuming that the user wants something less block-like
$default-box-orient: horizontal !default;

// Box orientation [ horizontal | vertical | inline-axis | block-axis | inherit ]
@mixin box-orient(
  $orientation: $default-box-orient
) {
  $orientation : unquote($orientation);
  @include experimental(box-orient, $orientation,
    -moz, -webkit, not(-o), -ms, not(-khtml), official
  );
}

// Default box-align
$default-box-align: stretch !default;

// Box align [ start | end | center | baseline | stretch ]
@mixin box-align(
  $alignment: $default-box-align
) {
  $alignment : unquote($alignment);
  @include experimental(box-align, $alignment,
    -moz, -webkit, not(-o), -ms, not(-khtml), official
  );
}

// Default box flex
$default-box-flex: 0 !default;

// mixin which takes an int argument for box flex. Apply this to the children inside the box.
//
// For example: "div.display-box > div.child-box" would get the box flex mixin.
@mixin box-flex(
  $flex: $default-box-flex
) {
  @include experimental(box-flex, $flex,
    -moz, -webkit, not(-o), -ms, not(-khtml), official
  );
}

// Default flex group
$default-box-flex-group: 1 !default;

// mixin which takes an int argument for flexible grouping
@mixin box-flex-group(
  $group: $default-box-flex-group
) {
  @include experimental(box-flex-group, $group,
    -moz, -webkit, not(-o), -ms, not(-khtml), official
  );
}

// default for ordinal group
$default-box-ordinal-group: 1 !default;

// mixin which takes an int argument for ordinal grouping and rearranging the order
@mixin box-ordinal-group(
  $group: $default-ordinal-flex-group
) {
  @include experimental(box-ordinal-group, $group,
    -moz, -webkit, not(-o), -ms, not(-khtml), official
  );
}

// Box direction default value
$default-box-direction: normal !default;

// mixin for box-direction [ normal | reverse | inherit ]
@mixin box-direction(
  $direction: $default-box-direction
) {
  $direction: unquote($direction);
  @include experimental(box-direction, $direction,
    -moz, -webkit, not(-o), -ms, not(-khtml), official
  );
}

// default for box lines
$default-box-lines: single !default;

// mixin for box lines [ single | multiple ]
@mixin box-lines(
  $lines: $default-box-lines
) {
  $lines: unquote($lines);
  @include experimental(box-lines, $lines,
    -moz, -webkit, not(-o), -ms, not(-khtml), official
  );
}

// default for box pack
$default-box-pack: start !default;

// mixin for box pack [ start | end | center | justify ]
@mixin box-pack(
  $pack: $default-box-pack
) {
  $pack: unquote($pack);
  @include experimental(box-pack, $pack,
    -moz, -webkit, not(-o), -ms, not(-khtml), official
  );
}