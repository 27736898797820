// Grid Syntax
// ===========


// Layout
// ------
// Set a new layout using a shorthand
// - $layout: <settings>
// - $clean: boolean
@mixin layout(
  $layout,
  $clean: false
) {
  $inspect  : $layout;
  $susy     : _get-layout($layout, $clean) !global;

  @include susy-inspect(layout, $inspect);
}


// Use Grid
// --------
// Use an arbitrary layout for a section of code
// - $layout: <settings>
// - $clean: boolean
@mixin with-layout(
  $layout,
  $clean: false
) {
  $inspect  : $layout;
  $old      : $susy;
  $susy     : _get-layout($layout, $clean) !global;

  @include susy-inspect(with-layout, $inspect);

  @content;

  $susy: $old !global;
}


// Layout
// ------
// Return a parsed layout map based on shorthand syntax
// - $layout: <settings>
@function layout(
  $layout: $susy
) {
  @return parse-grid($layout);
}


// Get Layout
// ----------
// Return a new layout based on current and given settings
// - $layout: <settings>
// - $clean: boolean
@function _get-layout(
  $layout,
  $clean: false
) {
  $layout: layout($layout);
  @return if($clean, $layout, _susy-deep-merge($susy, $layout));
}
