// Context Syntax
// ==============

// Nested [function]
// -----------------
// Return a subset grid for nested context.
// - $context   : <span>
@function nested(
  $context
) {
  $context    : parse-span($context);
  $span       : susy-get(span, $context);
  $location   : get-location($context);
  $columns    : susy-get(columns, $context);

  @return susy-slice($span, $location, $columns);
}

// Nested [mixin]
// --------------
// Use a subset grid for a nested context
// - $context   : <span>
// - @content   : <content>
@mixin nested(
  $context
) {
  $inspect  : $context;
  $context  : parse-span($context);
  $old      : susy-get(columns);
  $susy     : map-merge($susy, (columns: nested($context))) !global;

  @include susy-inspect(nested, $inspect);
  @content;

  $susy     : map-merge($susy, (columns: $old)) !global;
}
