// Requirements
// ============

@import "susy";
@import "compass";
@import "normalize";

$susy: (
  columns: 12,
  container: 1200px,
  gutters: 1/4,
 // debug: (image: show),
);

@include border-box-sizing;
main.wrapper {
    @include container;
}

//Breakpoints
$small: 0px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;

//header

header {
    @include span(12);
}

//responsive nav


// margin
.right-left-margin {
    margin-left: 1em;
    margin-right: 1em;
}

//text content


.content-row {
    overflow: auto;
    background-color: red;
    clear: both;
}


.full-width {
    @include span(12);
}

.half-width {
    @include span(12);
    
        @media (min-width: $medium) {
            @include span(6);
            
            &:nth-child(2) {
                @include last;
            }
        }
}


.three-column {
    @include span(12);
    
        @media (min-width: $medium) {
            @include span(4);
            
            &:nth-child(3) {
                @include last;
            } 
        }
}

//426px

.link-box {
    @include span(12);
            
    // FOR LARGE PHONES/LANDSCAPE PHONE
    @media (min-width: 426px) {
            @include span(6);
            
            &:nth-child(3) {
                @include last;
            }
        }
    
    
    @media (min-width: $medium) {
            @include span(4);
            
        // OVERWRITE 426px NTH CHILD
            &:nth-child(3) {
            @include span(4);
        }
        
            &:nth-child(4) {
                @include last;
            }
        }
}

//tile wall

.tile-wall {
    @include span(12);

    .tile-wall-square {
        @media (min-width: $small) {
                @include span(4 inside no-gutters);
                &:nth-child(3n) {
                    @include first;
                }
        }
        @media (min-width: $medium) {
                @include span(3 inside no-gutters);
                &:nth-child(4n) {
                    @include first;
                }
        }

    }
}


//portrait images

.portrait-imgs {
    @include span(12);
            @media (min-width: $medium) {
            @include span(4);
            
            &:nth-child(3n) {
                @include last;
            }
        }
}


// footer

footer {
    @include span(12);
             .footer-section {
                @media (min-width: $medium) {
                @include span(4);
                &:nth-child(3) {
                    @include last;
                    }
                }
            }
        }

.footer-top-bar {
    @include span(12);
}

.footer-end {
    @include span(12);
}