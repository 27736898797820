// Contact
// ============

@import "../partials/colours";

 
#map {
    width: 100%;
   height: 400px;
}

.grey {//Font Awesome icon color
    color: $grey;
    padding-right: 5px;
}

.social-icon-a {
    text-decoration: none;
}