// Tile Wall
// ============

@import "../partials/colours";
@import "../partials/typography";

 

.link-box {
    height: auto;
    width: auto;    
}


.link-box-img {
    width: 100%;
    height: auto;
   // padding-bottom: 50px;
}

.link-box-text {
    background-color: $grey;
    border-style: solid;
    border-width: 10px;
    border-color: $white;
}

.link-box-text h3 {
    color: $white;
    text-align: center;
    margin: 0px;
    padding-top: 10px;
}

.link-box-text p {
    color: $white;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 100;
    margin-bottom: 20px;
    margin-top: 0px;
    font-weight: $light;
}

.link-box-a{
    text-decoration: none;
}