// Tile Wall
// ============

@import "../partials/colours";

 
img {
    width: 100%;
    height: auto;
}

.tile-wall {
        margin-top: 20px;
        margin-bottom: 0px;
        /*background-color: #C06C84;*/
    }
       
.tile-wall-square img {
//        border-left: 2.5px solid $pink;
//        border-right: 2.5px solid $pink;
//        border-top: 2.5px solid $pink;
//        border-bottom: 2.5px solid $pink;
        float: left;
    
    
}

.tile-link {
        text-decoration: none;
}