// Screen
// ======

@import 'compass';
@import 'colours';


// Font weights
$light: 300;
$regular: 400;
$bold: 600;

// Base Font
$base-font-family: 'Nunito Sans', sans-serif; //"Avenir Next", Helvetica, Arial, sans-serif;
$base-font-weight: $regular;
$base-font-colour: $grey;
$base-font-size: 1em;
$base-line-height: 1.4;


h4, h5, h6, body, p, body {
    font-family: $base-font-family;
    color: $base-font-colour;
    font-size: $base-font-size;
    font-weight: $base-font-weight;
}


h1 {
    font-family: $base-font-family;
    color: $base-font-colour;
    font-size: 1.6em;
    font-weight: $base-font-weight;
}

h2 {
    font-family: $base-font-family;
    color: $base-font-colour;
    font-size: 1.4em;
    font-weight: $base-font-weight;
}

h3 {
    font-family: $base-font-family;
    color: $base-font-colour;
    font-size: 1.2em;
    font-weight: $base-font-weight;
}

.jm {
    color: #C8C8C8 !important;
    font-size: .7em;
    text-decoration: none;
}