// Susy Next Syntax
// ================

$susy-version: 2.1;

@import "../su";
@import "../output/float";

@import "susy/settings";
@import "susy/validation";
@import "susy/grids";
@import "susy/box-sizing";
@import "susy/context";
@import "susy/background";
@import "susy/container";
@import "susy/span";
@import "susy/gutters";
@import "susy/isolate";
@import "susy/gallery";
@import "susy/rows";
@import "susy/margins";
@import "susy/padding";
@import "susy/bleed";
@import "susy/breakpoint-plugin";
