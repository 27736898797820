// Images
// ============

@import "../partials/colours";


.full-width-img {
    width: 100%;
    overflow: hidden;
    position: relative;
  //  padding-top: 15px; //delete
        
        @media (min-width: $medium) {
            padding-top: 0px;
        }
}


.full-width-img img { // TRIMS IMG TO SHOW MIDDLE SECTION ON MOBILE
    height: auto;
    width: 150%;
    margin-left: -5em;
    vertical-align: top; //sits text block directly below img on mobile
    
        @media (min-width: $medium) {
        height: 100%;
        width: 100%;
        margin-left: 0;
//        position: absolute;
//        top: 0;
//        left: 0;
    }
}


// PAGE TITLES TXT ON IMG

.page-title {
    background-color: $light-grey;
    padding-top: 10px;
    padding-bottom: 10px;
    
        @media (min-width: $medium) {
            position: absolute;
            top: 25%;
            left: 0;
            width: 40%;
          //  text-align: center;
//            z-index: 10;
        }

        h1 {
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    text-align: center;
    }
}


//TXT ON IMG CONTENT - IN-PAGE

.text-on-img-left {
    background-color: $light-grey;
    padding-top: 10px;
    padding-bottom: 10px;
    
        @media (min-width: $medium) {
            position: absolute;
            top: 10%;
            left: 0;
            width: 60%;
          //  text-align: center;
//            z-index: 10;
        }
    
        @media (min-width: $large) {
            width: 40%;
            top: 20%;
        }
    
    
    
    
    h3 {
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0;
    }
    
    p {
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    margin: 0;
    //font-size: 1.2em;
        
        a {
        color: $white;
        }
        
    }
}



.text-on-img-right {
    background-color: $light-grey;
    padding-top: 10px;
    padding-bottom: 10px;
    
        @media (min-width: $medium) {
            position: absolute;
            top: 20%;
            right: 0; // or left: 0;
            width: 60%;
//            z-index: 10;
        }
    
    @media (min-width: $large) {
            width: 40%;
            top: 25%;
        }
    
    
    h3 {
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0;
    }
    
    p {
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    margin: 0;
    //font-size: 1.2em;
    }
    
}


.pink-bar {
    
    background-color: $pink;
    height: 0.2em;
    
    @media (min-width: $medium) {
            background-color: $pink;
            height: 0.8em;
        }
}



.last-img {
    display:none;
        
    @media (min-width: $medium) {
        display: block;
    }
}



// PAST PRODUCTIONS GALLERY


.scroll-section {
    overflow: auto;
    white-space: nowrap;
    width: 100%;
   // position: fixed;
}


.medium-block {
 //   background-color: red;
    display: inline-block;
    width: 226px;
    height: 130px;
    margin-right: 10px;
    margin-bottom: 10px;
    
     @media (min-width: $medium) {
            width: 312px;
            height: 180px;
            margin-right: 15px;
            margin-bottom: 10px;
     }
}

.small-block {
  //  background-color: blue;
    display: inline-block;
    width: 163px;
    height: 94px;
    margin-right: 10px;
    margin-bottom: 10px;
    vertical-align: top;
    
    @media (min-width: $medium) {
        width: 226px;
        height: 130px;
        margin-right: 15px;
        margin-bottom: 10px;
    }
}

.large-block {
  //  background-color: blue;
    display: inline-block;
    width: 346px;
    height: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: -105px;
  //  vertical-align: top;
    
    @media (min-width: $medium) {
        width: 481px;
        height: 271px;
        margin-right: 15px;
        margin-bottom: 10px;
        margin-top: -145px;
    }
}

.row-one {
   // vertical-align: top;
}


// PORTRAIT IMGS

.portrait-imgs {
    padding-bottom: 5%;
    
        @media (min-width: $medium) {
            padding-bottom: 2%;
        }
}


// MOVING BANNER

.moving-banner {
    background-color: $grey;
    
    h1 {
        padding-top: 15px;
        color: $white;
    }
    
    h4 {
        padding-bottom: 15px;
        color: $white;
        font-weight: $light;
    }
}