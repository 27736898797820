// Float Isolation API
// ===================

// Isolate Output
// --------------
// - $push    : <length>
// - [$flow]  : ltr | rtl
@mixin isolate-output(
  $push,
  $flow: map-get($susy-defaults, flow)
) {
  $to: to($flow);
  $from: from($flow);

  $output: (
    float: $from,
    margin-#{$from}: $push,
    margin-#{$to}: -100%,
  );

  @include output($output);
}
