// Map Functions
// =============


// Truncate List
// -------------
// - Return a list, truncated to a given length
@function _susy-truncate-list(
  $list,
  $length
) {
  $_return: ();

  @for $i from 1 through length($list) {
    $_return: if($i <= $length, append($_return, nth($list, $i)), $_return);
  }

  @return $_return;
}


// Deep Get
// --------
// - Return a value deep in nested maps
@function _susy-deep-get(
  $map,
  $keys...
) {
  $_return: $map;

  @each $key in $keys {
    @if type-of($_return) == map {
      $_return: map-get($_return, $key);
    }
  }

  @return $_return;
}


// Deep Set
// --------
// - Set a value deep in nested maps
@function _susy-deep-set(
  $map,
  $keys-value...
) {
  $_value: nth($keys-value, -1);
  $_keys: _susy-truncate-list($keys-value, length($keys-value) - 1);
  $_length: length($_keys);
  $_return: ();

  @for $i from 1 through $_length {
    $_n: 0 - $i;
    $_level: _susy-truncate-list($_keys, $_length + $_n);
    $_level: _susy-deep-get($map, $_level...);
    $_merge: nth($_keys, $_n);
    $_merge: ($_merge: $_value);
    $_return: if($_level, map-merge($_level, $_merge), $_merge);
    $_value: $_return;
  }

  @return $_return;
}


// Deep Merge
// ----------
// Return 2 objects of any depth, merged
@function _susy-deep-merge(
  $map1,
  $map2
) {

  @if type-of($map1) != map or type-of($map2) != map {
    $map1: $map2;
  } @else {
    @each $key, $value in $map2 {
      $_new: ($key: _susy_deep-merge(map-get($map1, $key), $value));
      $map1: map-merge($map1, $_new);
    }
  }

  @return $map1;
}


// Deep Has-Key
// ------------
// - Return true if a deep key exists
@function _susy-deep-has-key(
  $map,
  $keys...
) {
  $_return: null;
  $_stop: false;

  @each $key in $keys {
    @if not($_stop) {
      $_return: map-has-key($map, $key);
    }

    @if $_return {
      $map: map-get($map, $key);
    } @else {
      $_stop: true;
    }
  }

  @return $_return;
}
