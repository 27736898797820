// Tile Wall
// ============

@import "../partials/colours";
@import "../partials/typography";

 
footer {
    background-color: $grey;
}

footer ul {
    list-style: none;
    padding-left: 0;
}

.footer-section {
    padding-left: 1em;
    
    @media (min-width: $medium) {
        padding-left: 1.5em;
    }
    
    @media (min-width: $large) {
        padding-left: 4em;
    }
    
}

.footer-section p {
    color: $white;
}

.footer-top-bar { 
    background-color: $pink;
    height: 1em;
    margin-top: 0px;
}

.white { //Font Awesome icon color
    color: $white;
    padding-right: 5px;
}

.follow-us {
    margin-bottom: 0px;
}

.social-icons {
    margin-top: 5px;
}

.footer-end p {
    color: $white;
    text-align: center;
}

.footer-end hr {
    width: 10em;
}