// Grid Background API
// ===================
// - Sub-pixel rounding can lead to several pixels variation between browsers.

// Grid Background Output
// ----------------------
// - $image: background-image
// - $size: background-size
// - $clip: background-clip
// - [$flow]: ltr | rtl
@mixin background-grid-output (
  $image,
  $size: null,
  $clip: null,
  $flow: map-get($susy-defaults, flow)
) {
  $output: (
    background-image: $image,
    background-size: $size,
    background-origin: $clip,
    background-clip: $clip,
    background-position: from($flow) top,
  );

  @include output($output);
}
