// Colours
// ======

$pink: #C06C84;
$white: #fff;
$grey: #333;
$light-grey: #4A4A4A;
$blue: #71dad2;

.box1, .box3, .box5, .box7 {
    background-color: $pink;
}

.box2, .box4, .box6, .box8 {
    background-color: $blue;
}