// Header
// ============

@import "../partials/colours";
@import "../partials/typography";
@import "../partials/grids";

@import "susy";
@import "compass";


// Variables


$link-vertical-padding:15px; //Controls Menu top & bottom padding
$link-horizontal-padding:20px; //Contols Menu left & right padding
$nav-height: 50px;
$menu-background:$pink; //Control Menu Color Scheme
$link-color:$white;
$link-hover:$grey;




// HEADER IMG

header {
    background-color: #4A4A4A;
}




 // NAV ATTEMPT v2.0
nav {
  width:100%;
  background: $menu-background;
  margin-bottom: 0px; // increase to give space below nav bar
  max-height:0;
  overflow:hidden;
  transition: all 0.5s ease;
    
    @media (min-width: $medium) {
        min-height:50px; // was 500px max-height
        width: 100%;
        display:block;
    }
    
}
 
//.has-subnav {
//    
//     @media (min-width: $medium) {
//        display: inline-block;
//     }
//
//}

.navigation-section {
    
     @media (min-width: $medium) {
         //   display: none;
            position: absolute;
          //  background-color: #f9f9f9;
            min-width: 160px;
         max-width: 1200px;
            //box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
     }

}

nav ul {
 // width:1100px;
  list-style:none;
  margin:0;
  padding:0;
//  width:100%;
 // z-index:15;
    
    @media (min-width: $medium) {
        margin: 0;
        padding: 0;
        overflow: hidden;
        
       // position: relative;
        //z-index: 1;
    }
  
  
  li {
    //  position:relative;
    //  z-index:1;
    //  float:none;
    //  position:relative;
    //  margin:0;
    
        @media (min-width: $medium) {
          float: left;
            padding-right: 2em;
            //  display: inline-block;
          //  width: 33%;
//            list-style: none;
//            position: static;
//            vertical-align: top;
//        //    float: left;
        //    width: 200px;
            //height: 50px;
    }
      
    
    
    a {
        padding: $link-vertical-padding $link-horizontal-padding;
        text-align:center;
        color:$link-color;
        text-decoration:none;
        transition: all 0.1s ease;
        display:table;
        margin:0;
        padding:15px 2px;
        border-top:1px solid rgba(0,0,0,.15);
        width:100%;
        text-align: left;
        padding-left: 15px;
        
        @media (min-width: $medium) {
         //   display: block;
            border-top: none;
            display: inline-block;
            max-height: 50px;
        //    padding: 14px 16px;
        //    float: left; //codepen
        //   min-height: 60px; //codepen
        //    line-height: 60px; //codepen
    }
      
      &:hover {
        color:$link-hover;
        transition: all 0.2s ease;
      }
    }
    
    &:hover ul {
      //opacity:1;
    //  max-height:1000px;
      transition: all 0.3s ease;
      //  max-height:0;
        
        @media (min-width: $medium) {
         //   display: block; //codepen
        }
    }
      
      
//.navigation-section {
//    display: inline-block;
//}
    
    /******************
    SUBNAV
    ******************/      
      
      
      
    ul {
      background:darken($menu-background,5%);
      list-style:none;
      padding:0;
      margin: 0;
   //   position:absolute;
      width:200px;
   //   max-height:0;
      z-index:0;
   //   opacity:0;
   //   font-size:1em; //sub-nav font size
      transition: all 0.3s ease;
        max-height:0;
        opacity:1;
        width:100%;
   //     position:relative;
        overflow:hidden;
        transition: all 2s ease;
        
        @media (min-width: $medium) {
        //    position: absolute; //codepen
        //    display: inline-block;
        //    width: 100%; //codepen
        //    position: relative;
           // top: 60px; //codepen
           // left: 60px; //codepen
            
            
            
        } 
       
      li {
        float:none;
        margin:0;
          
          @media (min-width: $medium) {

              display: inline-block;
              width: 32%;
              vertical-align: text-top;
              
    }
        
        a {
          display: table;
          text-align:left;
          //padding:$link-vertical-padding/1.5 $link-horizontal-padding/1.5;
          margin:0;
          border-right:none;
          border-top:1px solid darken($menu-background,12%);;
          transition: all 0.2s ease;
          color:white;
          padding-left:20px;
          padding-top: 0px;
          padding-bottom: 0px; 
          background:none;
            
            @media (min-width: $medium) {
         //   display: inline-block;
                border-top: none;
                padding-top: 10px;
                padding-bottom: 10px;
        //    padding: 14px 16px;
    }
          
          &:hover {
            color:$link-hover;
            background:lighten($menu-background,5%);
            transition: all 0.5s ease;
          }
        }
          
          h3 {
              
              color: white !important;
              font-size:1em;
              
                @media (min-width: $medium) {
                    text-decoration: underline;
                }
          }
           
        p { //link descriptions
            
            display: none; //don't display on mobile
            
            @media (min-width: $medium) {
            display: block;
            color: white;
            font-size:0.85em;
          //  padding-left:20px;
        //    padding-right: 10px;
          //  margin-top: 0px;
            }
        }
      }
    }
  }
}


/***************************
MOBILE NAV STYLES
***************************/
.pink-nav-bar {
    background: $menu-background;
    
        @media (min-width: $medium) {
           // height: 160px;
            display: none;
        }
    
//        a { 
//            @media (min-width: $medium) {
//                //color: transparent;
//                display: none; // hide mobile burger bar
//                }
//        }
} 

.mobile {
 // display:none;
 // margin:0;
  padding:20px 15px;
  //background: linear-gradient(to bottom, #353535 0%,#262626 100%);
 // position:absolute;  
  color:white;
  text-decoration:none;
 // top:15px;
//  right:15px;
  line-height:0;
//  box-shadow:0px 2px 2px rgba(0,0,0,.35);
    display: table;
}

.active {
  max-height:9000px !important;
  transition: all 1s ease;
 }